import type { Document } from '@contentful/rich-text-types'
import md5 from 'md5'
import type { FunctionComponent } from 'react'
import React, { Suspense, useMemo } from 'react'

import type { RichTextProps } from '../../contentful-components/rich-text'
import { RichText } from '../../contentful-components/rich-text'
import type {
    CustomImageBlock,
    SanitizedHtmlBlock,
} from '../../contentful-components/rich-text/render'
import {
    CUSTOM_IMAGE,
    SANITIZED_HTML,
} from '../../contentful-components/rich-text/render'
import type { CacheStore } from '../../lib/store/CacheStore'
import { useStores } from '../../lib/store/useStores'
import type { Suspender } from '../../lib/utils/suspense'
import { suspender } from '../../lib/utils/suspense'
import type { WithTestId } from '../../lib/utils/testid'
import { extendConsole } from '../../shared/logging/console'

const logger = extendConsole('markdown')

/**
 * Converts markdown to rich text and renders it.
 */
export const MarkdownRenderer: FunctionComponent<Props> = React.memo(
    ({ source, ...props }) => {
        const { cacheStore } = useStores()

        return useMemo(
            () => (
                <Suspense>
                    <Inner
                        {...props}
                        renderer={suspender(
                            renderMarkdown(source, cacheStore).then((r) => ({
                                json: r,
                            }))
                        )}
                    />
                </Suspense>
            ),
            [source]
        )
    }
)

const Inner: FunctionComponent<{
    renderer: Suspender<{ json: Document }>
}> = ({ renderer, ...props }) => (
    <RichText source={renderer.readData()} {...props} />
)

export async function renderMarkdown(source: string, cacheStore: CacheStore) {
    const key = md5(source)

    if (!cacheStore.has(key)) {
        const { richTextFromMarkdown } = await import(
            '@contentful/rich-text-from-markdown'
        )

        cacheStore.set(
            key,
            await richTextFromMarkdown(source, async (markdownNode) => {
                switch (markdownNode.type) {
                    case 'image': {
                        const { url, alt } = markdownNode as any
                        const richTextNode: CustomImageBlock = {
                            nodeType: CUSTOM_IMAGE,
                            content: [],
                            data: {
                                src: url,
                                alt,
                            },
                        }
                        return richTextNode
                    }

                    case 'html': {
                        const { default: sanitizeHtml } = await import(
                            'sanitize-html'
                        )

                        const html = sanitizeHtml((markdownNode as any).html, {
                            allowedAttributes: {
                                a: ['href'],
                            },
                        })

                        if (!html) return null

                        const richTextNode: SanitizedHtmlBlock = {
                            nodeType: SANITIZED_HTML,
                            content: [],
                            data: {
                                html,
                            },
                        }
                        return richTextNode
                    }

                    default:
                        logger.error('Unsupported markdown node', markdownNode)
                        return null
                }
            })
        )
    }

    return cacheStore.get(key) as Document
}

type Props = WithTestId<
    Omit<RichTextProps, 'source'> & {
        /** Raw Markdown */
        source: string
    }
>
