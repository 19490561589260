import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'

import type { BlockFragment } from '../../graphql/fragments/BlockFragment.graphql'
import variables from '../../styles/variables.module.scss'
import styles from './block.module.scss'
import TextBlock from './text-block'

/**
 * Section that displays a block for the 9 block grid.
 */

export const Block: FunctionComponent<Props> = ({
    titleMd = '',
    subtitleMd = '',
    subtextTopMd = '',
    image = null,
    backgroundColor = 'black',
    isCenterBlock = false,
    path = '',
}) => {
    const router = useRouter()

    function getBackgroundStyle() {
        return {
            backgroundColor: variables[backgroundColor],
            cursor: !isCenterBlock && path !== '' ? 'pointer' : 'auto',
        }
    }

    const navigate = () => {
        if (isCenterBlock) return
        if (path === '') return
        router.push(path)
    }

    return (
        <div
            className={styles.item}
            style={getBackgroundStyle()}
            onClick={navigate}
        >
            <div>
                {image?.url && (
                    <div
                        className={`${styles.imageContainer} ${
                            isCenterBlock
                                ? styles.centerBlockImageContainer
                                : ''
                        }`}
                    >
                        <img
                            src={image.url}
                            width={image.width}
                            height={image.height}
                            alt={image?.alt}
                            className={`${styles.image} ${
                                isCenterBlock ? styles.centerBlockImage : ''
                            }`}
                        />
                    </div>
                )}

                <TextBlock
                    textField={subtextTopMd}
                    isCenterBlock={isCenterBlock}
                />
                <TextBlock textField={titleMd} isCenterBlock={isCenterBlock} />
                <TextBlock
                    textField={subtitleMd}
                    isCenterBlock={isCenterBlock}
                />
            </div>
        </div>
    )
}

export default Block

type Props = Omit<BlockFragment, 'sys' | 'type'>
