import type { FunctionComponent } from 'react'

import { Markdown } from '../../components/markdown'
import { usePersonalizations } from '../../lib/hooks/usePersonalizations'
import { renderTemplate } from '../../lib/utils/text'
import styles from './block.module.scss'

interface TextBlockProps {
    textField: string
    isCenterBlock: boolean
}

export const TextBlock: FunctionComponent<TextBlockProps> = ({
    textField = '',
    isCenterBlock = false,
}) => {
    const personalizations = usePersonalizations()

    return (
        <div>
            {textField && !isCenterBlock && (
                <div className={styles.subtitle}>
                    <Markdown
                        typeStyle={{ _: 'body-lg', md: 'body-md' }}
                        markdown={renderTemplate(textField, personalizations)}
                    />
                </div>
            )}
        </div>
    )
}

export default TextBlock
