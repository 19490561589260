import Link from 'next/link'
import type { FunctionComponent, ReactNode } from 'react'

import type { CustomRenderers } from '../../contentful-components/rich-text/render'
import type { OptionallyResponsiveProp } from '../../lib/utils/responsiveProps'
import { MarkdownRenderer } from '../markdown-renderer'
import { Text } from '../text'
import type colorStyles from '../text/text-colors.module.scss'
import type typeStyles from '../text/type-styles.module.scss'
import { replaceForSuperScript, replaceForSymbol } from './utils'

const EXTERNAL_URL_REGEX = /https?:\/\//

export const Markdown: FunctionComponent<Props> = ({
    markdown,
    accentColor = 'primary',
    boldColor,
    tag = 'p',
    typeStyle = 'body-medium',
    color = 'default',
}) => {
    if (!markdown) {
        return null
    }

    // get custom renderers for the 2022 website design
    const renderers = getRenderers({
        accentColor,
        tag,
        typeStyle,
        boldColor,
        color,
    })

    return <MarkdownRenderer renderers={renderers} source={markdown} />
}

const getRenderers = ({
    accentColor,
    boldColor,
    tag,
    typeStyle,
    color,
}: Omit<Props, 'markdown'>): CustomRenderers => {
    return {
        text: (text) => {
            // if text from contentful has a literal new line in it, replace it with a real one
            if (text.includes('\\n')) {
                text = text.replace(/\\n/g, ' \n')
            }

            return text.split(/\n/g).reduce((children, textSegment, index) => {
                // handle superscript replacements (E.g. replace ^1 in text with <sup>1</sup>)
                if (textSegment.includes('^')) {
                    // find all instances of ^1 or ^2 etc and replace with a string sup tag
                    const replacedText = replaceForSuperScript(textSegment)

                    return [
                        ...children,
                        index > 0 && <br key={index} />,
                        <span
                            key='replacedText'
                            dangerouslySetInnerHTML={{
                                __html: replacedText,
                            }}
                        />,
                    ]
                }

                if (
                    textSegment.includes('~sym') ||
                    textSegment.includes('~supsym')
                ) {
                    const replacedText = replaceForSymbol(textSegment)

                    return [
                        ...children,
                        index > 0 && <br key={index} />,
                        <span
                            key='replacedText'
                            dangerouslySetInnerHTML={{
                                __html: replacedText,
                            }}
                        />,
                    ]
                }

                return [
                    ...children,
                    index > 0 && <br key={index} />,
                    textSegment,
                ]
            }, [] as ReactNode[])
        },
        italic: (node) => (
            <Text typeStyle={typeStyle} color={accentColor} tag='span'>
                {node}
            </Text>
        ),
        bold: (node) => (
            <Text
                typeStyle={typeStyle}
                color={boldColor ?? color}
                tag='span'
                bold
            >
                {node}
            </Text>
        ),
        listItem: (_, children) => (
            <Text tag='li' typeStyle={typeStyle} color={color}>
                {children}
            </Text>
        ),
        paragraph: (_, children) => (
            <Text tag={tag} typeStyle={typeStyle} color={color}>
                {children}
            </Text>
        ),
        link: (node, children) => {
            const { uri } = node.data
            const isExternalUrl = uri.match(EXTERNAL_URL_REGEX)

            return (
                <Link
                    href={node.data.uri}
                    target={isExternalUrl ? '_blank' : '_self'}
                >
                    <Text typeStyle={typeStyle} tag='span' bold>
                        {children}
                    </Text>
                </Link>
            )
        },
    }
}

interface Props {
    accentColor?: keyof typeof colorStyles
    boldColor?: keyof typeof colorStyles
    color?: keyof typeof colorStyles
    markdown?: string
    typeStyle?: OptionallyResponsiveProp<keyof typeof typeStyles>
    tag?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span' | 'li'
}
